// axios
const https = require('https');
import axios from 'axios';
import Vue from 'vue';
import router from '../router';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  httpsAgent: new https.Agent({
    rejectUnauthorized: false  // TODO REMOVE this for production
  })
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent
    const accessToken = localStorage.getItem('accessToken')
    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (401 === error.response.status) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userData')
    localStorage.removeItem('userAbility')
    router.push({ name: 'auth-login' })
  } else {
    return Promise.reject(error);
  }
})

Vue.prototype.$http = axiosIns

export default axiosIns
